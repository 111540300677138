import { useGlobalManagementStore } from '@/modules/apartment/myInfo/useGlobalManagement';
import { Button } from 'antd';
import { useNavigate } from 'react-router';

export function NavigateSortSettingButton() {
	const { globalManagement } = useGlobalManagementStore();
	const navigate = useNavigate();
	return (
		globalManagement && (
			<Button
				onClick={async () => {
					navigate('/wood/sortSetting');
				}}
			>
				排序设置
			</Button>
		)
	);
}
