import { UploadOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import {
	Button,
	Drawer,
	Form,
	Table,
	Upload,
	message
} from 'antd';
import { WOOD_CODE } from 'blog/wood-code';
import dayjs, { extend } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { groupBy, isNil, values } from 'lodash-es';
import { type Key, useState, useTransition } from 'react';
import { codeTrans } from '../codeTrans';
import {
	getStockTableSetting,
} from './getStockTableSetting';
import { saveAs } from './saveAs';
import ExcelJS from 'exceljs';
// @ts-expect-error has type
import templateUrl from './template.xlsx?url';
extend(isBetween);

import { useGlobalManagementStore } from '@/modules/apartment/myInfo/useGlobalManagement';
import { useLocalStorageState } from 'ahooks';
import { honoClient } from '../honoClient';
import { roundFix2 } from '../roundFix2';
import { ImportDataPage } from './ImportDataPage';
import { NavigateSortSettingButton } from './NavigateSortSettingButton';
import { PrintModeView } from './PrintModeView';
import { StockControlForm } from './StockControlForm';
import { getBaseStockField } from './getBaseStockField';
import { titleHeaderConst } from './titleHeaderConst';

export default function StockTable({
	onDelivery,
	havingOps,
}: {
	havingOps?: boolean;
	onDelivery?: (v: any) => void;
}) {
	const { globalManagement, setGlobalManagement } = useGlobalManagementStore();

	const [form] = Form.useForm();
	const [editForm] = Form.useForm();
	const [addForm] = Form.useForm();
	const specifications = Form.useWatch('specifications', form);
	const brand = Form.useWatch('brand', form);
	const ack = Form.useWatch('ack', form);
	const br = Form.useWatch('br#', form);
	const remark = Form.useWatch('remark', form);
	const uuid = Form.useWatch('uuid', form);
	const showCols = Form.useWatch('showCols', form);
	const [rows, setRows] = useState<Key[]>([]);
	const [editRows, setEditRows] = useState<Key[]>([]);
	const [openInsert, setOpenInsert] = useState(false);
	const [previewExcel, setPreviewExcel] = useState<any[]>([]);

	const [isPending, startTransition] = useTransition();
	const [mode, setMode] = useLocalStorageState<'list' | 'print'>(
		'wood-stock-mode',
		{ defaultValue: 'list' },
	);

	const stockQuery = useQuery({
		queryKey: ['stock', specifications, brand, ack, br, remark, uuid],
		queryFn: async () => {
			const res = await honoClient.stock.$post({
				json: {
					uuid: uuid !== '' && !isNil(uuid) ? uuid : undefined,
					specifications,
					brand,
					ack,
					'br#': br,
					remark,
				},
			});

			return await res.json();
		},
	});

	const onClose = () => {
		setOpenInsert(false);
	};

	const latestSortQuery = useQuery({
		queryKey: ['latestSortQuery'],
		queryFn: async () => {
			const res = await honoClient.lastSortSetting.$get({
				json: {},
			});
			return await res.json();
		},
	});
	const sortSetting: string[] = latestSortQuery?.data?.specifications ?? [];

	const printModeData = Object.entries(
		groupBy(stockQuery?.data ?? [], (a) => {
			return `${a.specifications}-${a.brand}-${a.ack}`;
		}),
	)?.sort((a, b) => {
		const aSpecifications = a?.[1]?.[0].specifications;
		const aIdx = sortSetting?.findIndex((cc) => {
			return cc === aSpecifications;
		});

		if (aIdx === -1) {
			return 1;
		}
		const bSpecifications = b?.[1]?.[0]?.specifications;
		const bIdx = sortSetting?.findIndex((cc) => {
			return cc === bSpecifications;
		});

		if (bIdx === -1) {
			return 1;
		}

		return aIdx - bIdx;
	});

	const printModeRes = printModeData.map((a, idx) => {
		return a[1]?.map((b, bIdx, bArray) => {
			const total = roundFix2(
				bArray.reduce((p, c) => {
					return p + Number.parseFloat(c.cubic);
				}, 0),
			);

			return {
				key: b.uuid,
				specifications: bIdx === 0 ? b.specifications : '',
				brand: bIdx === 0 ? b.brand : '',
				ack: bIdx === 0 ? b.ack : '',
				'br#': b['br#'],
				lengths: b.lengths,
				pcs: b.pcs,
				bf: b.bf,
				cubic: b.cubic,
				remark: b.remark,
				total: bIdx === bArray.length - 1 ? total : '',
			};
		});
	});

	return (
		<div>
			<ImportDataPage
				previewExcel={previewExcel}
				setPreviewExcel={setPreviewExcel}
			/>

			{onDelivery && (
				<div className="flex justify-end my-2">
					<Button
						onClick={() => {
							if (rows.length === 0) {
								message.error('没有选中');
								return;
							}
							onDelivery(
								rows.map((a) => stockQuery?.data?.find((b) => b.uuid === a)),
							);
						}}
					>
						出库
					</Button>
				</div>
			)}

			{havingOps && (
				<div className="flex flex-row gap-2 my-2 justify-end flex-wrap">
					<Button
						onClick={() => {
							setMode(mode === 'list' ? 'print' : 'list');
						}}
					>
						{mode === 'list' ? '切换制表模式' : '切换列表模式'}
					</Button>


					<NavigateSortSettingButton />
					<Button
						onClick={() => {
							const workbook = new ExcelJS.Workbook();
							workbook.creator = 'w.laikezhan.com';
							workbook.lastModifiedBy = 'w.laikezhan.com';
							workbook.created = new Date();
							const worksheet = workbook.addWorksheet('stock');
							const bStyle = { style: 'thin', color: { argb: 'FF969696' } };

							worksheet.pageSetup.margins = {
								top: 0.792,
								bottom: 0.792,
								left: 0.592,
								right: 0.192,
								header: 0.504,
								footer: 0.504,
							};

							const border = {
								top: bStyle,
								left: bStyle,
								bottom: bStyle,
								right: bStyle,
							};
							const scale = 0.9458;
							worksheet.getColumn(1).width = 13.29 / scale;
							worksheet.getColumn(2).width = 12.09 / scale;
							worksheet.getColumn(3).width = 11.7 / scale;
							worksheet.getColumn(4).width = 13.29 / scale;

							worksheet.getColumn(4).style = { font: { bold: true } };

							worksheet.getColumn(5).width = 6.85 / scale;
							worksheet.getColumn(6).width = 4.09 / scale;
							worksheet.getColumn(7).width = 7.29 / scale;
							worksheet.getColumn(8).width = 7.29 / scale;
							worksheet.getColumn(9).width = 8.29 / scale;
							worksheet.getColumn(10).width = 4.29 / scale;

							for (let i = 1; i <= 10; i++) {
								worksheet.getColumn(i).alignment = {
									vertical: 'middle',
									horizontal: 'center',
								};
							}

							worksheet.addRow([
								'',
								'',
								'',
								'',
								'',
								'',
								`制表日期:${dayjs().format('YYYY年MM月DD日')}`,
							]);
							worksheet.getRow(1).getCell(7).alignment = undefined;
							const titleHeader = worksheet.getRow(2);

							titleHeader.values = titleHeaderConst;
							titleHeader.font = { bold: true };
							worksheet.addRow([]);

							let baseLine = 3;
							for (
								let itemIdx = 0;
								itemIdx <= printModeRes.length - 1;
								itemIdx++
							) {
								const rItem = printModeRes[itemIdx] ?? [];

								for (const mItemIdx in rItem) {
									const mItem = rItem[mItemIdx];
									worksheet.addRow([
										...titleHeaderConst.map((a, idx, aArr) => {
											if (a === 'br#') {
												return `${mItem[a].toString()}`;
											}

											return mItem[a];
										}),
									]);

									baseLine++;
									for (let cellIndex = 1; cellIndex <= 9; cellIndex++) {
										worksheet.getRow(baseLine).getCell(cellIndex).border =
											border;
									}
								}
								worksheet.addRow([]);
								baseLine++;
							}

							workbook.xlsx.writeBuffer().then((data) => {
								const blob = new Blob([data], {
									type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
								});

								saveAs(blob, `stock-${dayjs().format('YYYY-MM-DD')}.xlsx`);
							});
						}}
					>
						导出数据
					</Button>

					<Upload
						multiple={false}
						maxCount={1}
						accept={'.xlsx'}
						showUploadList={false}
						beforeUpload={(file) => {
							return false;
						}}
						onChange={async (value) => {
							const currentFile = value?.file;

							const wb = new ExcelJS.Workbook();
							const reader = new FileReader();

							reader.onload = async () => {
								const buffer = reader.result;
								console.log(`buffer`, buffer);
								const workbook = await wb.xlsx.load(buffer as Buffer);

								console.log(workbook, 'workbook instance');
								const tempRowsData: { [key: string]: Excel.CellValue } | any[] =
									[];
								workbook.eachSheet((sheet, id) => {
									console.log(sheet, id);
									sheet.eachRow((row, rowIndex) => {
										console.log(row.values, rowIndex);
										tempRowsData.push(row.values.splice(1));
									});
								});

								console.log(`tempRowsData`, tempRowsData);
								setPreviewExcel(
									tempRowsData
										// 过滤第一行都没有的东西
										?.filter((a) => !isNil(a[1])),
								);
							};

							console.log(`currentFile`, currentFile);
							reader.readAsArrayBuffer(currentFile);
						}}
					>
						<Button icon={<UploadOutlined />}>导入数据</Button>
					</Upload>

					<Button
						onClick={() => {
							window.open(templateUrl);
						}}
					>
						下载导入模板
					</Button>
				</div>
			)}

			{havingOps && <div className="flex flex-row gap-2 my-2 justify-end flex-wrap">
				{rows.length > 0 && (
					<Button
						onClick={() => {
							if (editRows.length === 0) {
								editForm.resetFields();
							}

							if (rows.length === 0) {
								message.info('未选中');
								return;
							}

							editRows.length === 0 ? setEditRows(rows) : setEditRows([]);
						}}
					>
						{editRows.length > 0 ? '退出编辑' : '编辑'}
					</Button>
				)}
				<Button
					hidden={editRows.length === 0}
					disabled={editRows.length === 0}
					onClick={async () => {
						editForm.submit();
					}}
				>
					提交
				</Button>
				<Button
					disabled={rows.length === 0}
					onClick={async () => {
						const res = await (
							await honoClient.deleteStock.$post({
								json: (rows as string[]).map((i) => {
									return { uuid: i };
								}),
							})
						).json();

						if (res.code === 0) {
							message.success('删除成功');

							stockQuery.refetch();
							setRows([]);
						}
					}}
				>
					删除
				</Button>

				<Button
					onClick={async () => {
						setOpenInsert(true);
					}}
				>
					增加
				</Button>
			</div>}

			<Drawer title="增加库存" onClose={onClose} open={openInsert}>
				<Form
					form={addForm}
					onFinishFailed={(err) => {
						console.log(err);
					}}
					onFinish={async (v) => {
						const res = await (
							await honoClient.addStock.$post({
								json: v,
							})
						).json();

						if (res.code === WOOD_CODE.OK) {
							message.success(codeTrans[res.code as keyof typeof codeTrans]);

							stockQuery.refetch();
							addForm.resetFields();
							setRows([]);
							setOpenInsert(false);
							return;
						}

						message.error(codeTrans[res.code as keyof typeof codeTrans]);
					}}
				>
					{getBaseStockField().map((i) => {
						return (
							<Form.Item
								key={i.title}
								name={i.title}
								label={i.title}
								rules={[
									{ required: i?.options?.insertRequired, message: '请输入' },
								]}
							>
								{i.component}
							</Form.Item>
						);
					})}
					<Button
						type="default"
						onClick={() => {
							addForm.submit();
						}}
					>
						提交
					</Button>
				</Form>
			</Drawer>

			<Form form={form}>
				<StockControlForm isShowOptions={true} />
			</Form>

			{mode === 'print' && <div className='overflow-x-auto w-full'>
				<PrintModeView printModeRes={printModeRes} />
			</div>}

			{mode === 'list' && (
				<Form
					form={editForm}
					onFinish={async (v) => {
						const value = values(v);

						const res = await (
							await honoClient.updateStock.$post({
								json: value,
							})
						).json();

						if (res.code === 0) {
							message.success('更新成功');
							setEditRows([]);

							stockQuery.refetch();
							editForm.resetFields();
						}
					}}
				>
					<div className="flex flex-row gap-2 my-2 justify-end items-center">
						<div>当前选中{rows.length}条</div>
						<Button
							size="small"
							onClick={() => {
								setRows([]);
							}}
						>
							清除选中
						</Button>
					</div>
					<Table
						size="small"
						rowSelection={{
							selectedRowKeys: rows,
							onChange: (keys) => {
								setRows(keys);
							},
						}}
						dataSource={stockQuery.data}
						{...getStockTableSetting([], {
							cols: showCols,
							isEditUuid: editRows,
							havingCost: globalManagement
						})}
					/>
				</Form>
			)}
		</div>
	);
}
