import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { Button, message } from 'antd';
import { isNil, isNumber } from 'lodash-es';
import { WOOD_CODE } from 'blog/wood-code';
import { honoClient } from '../honoClient';
import { isString } from 'antd/es/button';
import style from './style.module.css';
import classNames from 'classnames';
import { getDataFromCell } from './getDataFromCell';

export function ImportDataPage({
	previewExcel,
	setPreviewExcel,
}: {
	previewExcel: any[];
	setPreviewExcel: React.Dispatch<React.SetStateAction<any[]>>;
}) {
	const currentData = previewExcel?.slice(1).map((a) => {
		return {
			specifications: getDataFromCell(a[0])?.toString(),
			brand: getDataFromCell(a[1])?.toString(),
			ack: getDataFromCell(a[2])?.toString(),
			'br#': getDataFromCell(a[3])?.toString(),
			lengths: getDataFromCell(a[4])?.toString(),
			pcs: getDataFromCell(a[5])?.toString(),
			bf: getDataFromCell(a[6]),
			cubic: getDataFromCell(a[7]),
			cost: getDataFromCell(a[9]),
			remark: getDataFromCell(a[8])?.toString(),
		};
	});

	const repeatStockQuery = useQuery({
		queryKey: ['repeatStockQuery', currentData],
		queryFn: async () => {
			const data = currentData;

			const res = await honoClient.checkImportStock.$post({
				json: data,
			});

			return await res.json();
		},
	});

	return (
		<>
			{previewExcel.length > 0 && (
				<>
					<h3>将导入的数据为</h3>

					<table className={classNames('border-collapse', style['my-table'])}>
						<thead>
							<tr>
								{previewExcel?.[0]?.map((a, idx) => {
									return <td key={idx}>{getDataFromCell(a)}</td>;
								})}
							</tr>
						</thead>
						<tbody>
							{previewExcel?.slice(1)?.map((a, index) => {
								return (
									<tr key={`${a[0]}-${a[3]}`}>
										{a.map((i) => {
											return <td key={i}>{getDataFromCell(i)}</td>;
										})}
									</tr>
								);
							})}
						</tbody>
					</table>

					{repeatStockQuery?.data?.error?.name === 'ZodError' && (
						<div>
							<h3>错误:</h3>
							{repeatStockQuery?.data?.error?.issues.map((a) => {
								return (
									<div key={a.path}>
										在 {a.path[0] + 1}行 {a.path[1]} 列: {a.message}
									</div>
								);
							})}
							<Button
								onClick={() => {
									setPreviewExcel([]);
								}}
							>
								我已知晓, 将修正数据重试
							</Button>
						</div>
					)}
					{repeatStockQuery?.data?.code === WOOD_CODE.REPEAT_STOCK && (
						<>
							<h3>重复库存</h3>
							{repeatStockQuery?.data?.data?.map((a) => {
								return (
									<div key={a.uuid}>
										存在重复库存 uuid 为: {a.uuid} 其
										specifications,brand,ack,br#,lengths,cubic完全相同
									</div>
								);
							})}
						</>
					)}
					{repeatStockQuery?.data?.code === WOOD_CODE.OK && (
						<Button
							className="my-3"
							onClick={async () => {
								if (isNil(previewExcel)) {
									return;
								}

								const data = previewExcel?.slice(1)?.map((a) => {
									return {
										specifications: getDataFromCell(a[0])?.toString(),
										brand: getDataFromCell(a[1])?.toString(),
										ack: getDataFromCell(a[2])?.toString(),
										'br#': getDataFromCell(a[3])?.toString(),
										lengths: getDataFromCell(a[4])?.toString(),
										pcs: getDataFromCell(a[5])?.toString(),
										bf: getDataFromCell(a[6]),
										cubic: getDataFromCell(a[7]),
										cost: getDataFromCell(a[9]),
										remark: getDataFromCell(a[8])?.toString(),
									};
								});

								const res = await honoClient.bulkImportStock.$post({
									json: data,
								});

								const opRes = await res.json();
								if (opRes.code === WOOD_CODE.OK) {
									message.success(`成功导入数据条数: ${opRes.data}`);
									setPreviewExcel([]);
									return;
								}

								message.warning(`导入失败 ${JSON.stringify(res.data)}`);
							}}
						>
							确认导入
						</Button>
					)}
				</>
			)}
		</>
	);
}
