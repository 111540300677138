export const titleHeaderConst = [
	'specifications',
	'brand',
	'ack',
	'br#',
	'lengths',
	'pcs',
	'bf',
	'cubic',
	'remark',
	'total',
];
